<template>
  <div class="vm-modal blikPayLater">
    <h2 class="normal" v-html="$t('configuration.paywayGroups.otp.blikPayLaterModal.header1')"></h2>
    <h4 class="normal" v-html="$t('configuration.paywayGroups.otp.blikPayLaterModal.header2')"></h4>
    <p v-html="$t('configuration.paywayGroups.otp.blikPayLaterModal.text2')"></p>
    <h4 class="normal" v-html="$t('configuration.paywayGroups.otp.blikPayLaterModal.header3')"></h4>
    <p v-html="$t('configuration.paywayGroups.otp.blikPayLaterModal.text3')"></p>
    <h4 class="normal" v-html="$t('configuration.paywayGroups.otp.blikPayLaterModal.header4')"></h4>
    <p v-html="$t('configuration.paywayGroups.otp.blikPayLaterModal.text4')"></p>
  </div>
</template>

<script>
export default {
  name: 'BlikPayLaterModal'
}
</script>
<style lang="scss" scoped>
.vm-modal {
  h4 {
    color: #3C3C46;
    margin-top: 20px;
  }

  p {
    margin-top: 10px;
  }
}
</style>
